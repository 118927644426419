.tableCell {
    padding:12px 10px;
    font-size:14px;
    color:#576475;

    b {
        color:#3E4247;
    }

    [data-box_loading] {
        display:inline-block;
        position:relative;
        top:1px;
        margin-left:2px!important;
        margin-right:7px!important;
    }

    svg {
        font-size:16px;
        color:#97a3b3;
        cursor:pointer;
        margin:0 4px;
    }

    [data-input] {
        margin:0 3px;

        input {
            padding:1px 5px;
        }
    }

    &.input_no_format {
        [data-input] {
            margin:0 0 10px 0;
            padding:8px 12px;
    
            input {
                padding:1px 2px;
            }

            &:last-child {
                margin-bottom:0;
            }
        }   

        [data-input_container] {
            [data-input] {
                margin:0 0 10px;
                padding:0;
        
                input {
                    padding:1px 5px;
                }

                &:last-child {
                    margin-bottom:0;
                }
            }   
        }
    }

    &:first-child {
        padding-left:20px;
    }

    &:last-child {
        padding-right:20px;
    }

    &.padding__lg {
        padding-top:20px;
        padding-bottom:20px;
    }

    >span.maxWidthNone {
        max-width:100%!important;
    }

    &.boxed > span {
        border-radius: 100px;
        color: #fff;
        font-weight: 400;
        min-width: 110px;
        text-align: center;
        padding: 3px 12px;
        font-size: 13px;
        width: auto;
        display: block;

        &.boxed_widthAuto {
            display:inline-flex;
            min-width:0;
        }
    }
}

@media print {
    .tableCell:first-child {
        padding-left:0;
    }

    .tableCell:last-child {
        padding-right:0;
    }
}

/* MOBILE */
@media screen and (max-width:768px){
    .tableCell {
        font-size:16px;

        [data-input] {
            margin-left:10px;
            margin-right:10px;
        }

        [data-icon] {
            svg {
                font-size:24px!important;
            }
        }
    }
}

/* PRINT */
@media print{
    .tableCell {
        [data-input] {
            border:none;
            
            input {
                padding:0;                
            }
        }
    }
}